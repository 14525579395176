<template>
    <div class="home">
    <div class="pt20"></div>
     <el-form :inline="true" :model="formInline" class="demo-form-inline">
            <el-form-item label="分类">
            <el-select style="width:200px;" @change="setstatus2" v-model="status" placeholder="请选择">
                    <el-option
                      v-for="(ot,i) in options"
                      :key="'a'+i"
                      :label="ot.name"
                      :value="ot.value">
                    </el-option>
            </el-select>
            <el-select style="width:200px;"  v-model="status2" placeholder="请选择">
                    <el-option
                      v-for="(ot,i) in options2"
                      :key="'a'+i"
                      :label="ot.name"
                      :value="ot.value">
                    </el-option>
            </el-select>
            </el-form-item>
            <el-form-item style="font-weight: bold;" label="关键字">
            <el-input v-model="formInline.search_data" style="width:400px" placeholder="请输入关键字"></el-input>
            <el-button  type="primary"  style="margin-left:20px" @click="onSubmit('CHA')">查询</el-button>
            </el-form-item>
      </el-form> 
      <div class="left">
        <el-button  @click="add()" type="primary" style="font-size: 16px;font-weight: bold;" plain>添加</el-button>
      </div>
      <div class="pt20"></div>
      <el-table
        v-loading="loading"
        :data="tableData"
        style="width: 100%"
        :header-cell-style="{background:'#F5F7FA'}"
        @selection-change="handleSelectionChange"
         >
       <el-table-column type="selection" width="55" align="center"/>
        <el-table-column
          prop="id"
          width="200"
          label="序号">
        </el-table-column>
        <el-table-column
          prop="enclosure_name"
          width="200"
          label="名称">
        </el-table-column>
        <el-table-column
          prop="create_time"
          label="创建时间">
          <template  slot-scope="scope">
            {{scope.row.create_time | formatDate}}
         </template>
        </el-table-column>
        <el-table-column
          prop="operator_name"
          label="操作人员">
        </el-table-column>
        <el-table-column
          label="操作">
          <template  slot-scope="scope">
            <el-button @click='bianji(scope.row.id)' size="mini" type="info" plain>编辑</el-button>
            <el-button @click='dd(scope.row.id)' size="mini" type="info" plain>删除</el-button>
         </template>
        </el-table-column>
      </el-table>

      <div class="pt20"></div>
        <el-pagination
          @current-change="handleCurrentChange"
          :current-page.sync="current_page"
          :page-size="per_page"
          layout="total,prev, pager, next, jumper"
          :total="total">
        </el-pagination>
        <el-dialog
        title="编辑"
        :visible.sync="eddbankshow"
        :fullscreen="false"
        :modal="false"
        width="80%"
        hight="80%"
        center>
        <div class='conentbox'>
          <el-form ref="registerdata" :model="registerdata" label-width="120px">
            <div class='contflex'>
            <el-form-item label="模板名称" prop="enclosure_name">
              <el-input v-model.trim="registerdata.enclosure_name" placeholder="请输入中文名称" maxlength="50" show-word-limit clearable required></el-input>
            </el-form-item>
            <el-form-item label="案件类型" prop="type">
              <el-select style="width:200px;"  v-model="registerdata.type" placeholder="请选择">
                    <el-option
                      label="行李"
                      :value="1">
                    </el-option><el-option
                      label="非行李"
                      :value="2">
                    </el-option>
            </el-select>
            </el-form-item>
            <el-form-item label="说明" prop="remark">
              <el-input v-model.trim="registerdata.remark" placeholder="请输入说明内容" type="textarea" maxlength="200" show-word-limit clearable required></el-input>
            </el-form-item>
            <el-form-item label="存储位置" prop="f_type">
              <el-select style="width:200px;" @change="setstatus33" v-model="registerdata.f_type" placeholder="请选择">
                      <el-option
                        v-for="(ot,i) in options"
                        :key="'a'+i"
                        :label="ot.name"
                        :value="ot.value">
                      </el-option>
              </el-select>
              <el-select style="width:200px;"  v-model="registerdata.c_type" placeholder="请选择">
                      <el-option
                        v-for="(ot,i) in options4"
                        :key="'a'+i"
                        :label="ot.name"
                        :value="ot.value">
                      </el-option>
              </el-select>
            </el-form-item>
            <el-form-item prop="c_type"> </el-form-item>
            <el-form-item label="问题类型" prop="enclosure_content">
            <div class="remarkds">
              <div> 
                <el-button class="btnblack" type="info" @click="adddom2(1)">下拉框</el-button>
                <el-button class="btnblack" type="info" @click="adddom2(2)">输入框</el-button>
                <el-button class="btnblack" type="info" @click="adddom2(3)">上传图片</el-button>
                <el-button class="btnblack" type="info" @click="adddom2(4)">损失清单</el-button>
                <el-button class="btnblack" type="info" @click="adddom2(5)">自行投诉记录</el-button>
              </div>
            </div>
            </el-form-item>
            </div>
            <div style='height:350px;'></div>
            <div class="pt20"></div>
            <div v-for="(dom,index) in registerdata.enclosure_content" :key="index" >
              <div>
                  类型：{{dom.title}} 
                  <el-checkbox class="domcheck malr" v-model="dom.is_must">必填项</el-checkbox>
                  <el-button class="btnblack" type="info" @click="deldom2(index)">删除</el-button>
              </div>
              <div class="pt20"></div>
              <div class="remarkds">
                <div class='bcedatatitle' style="line-height: 40px;">问题名称:</div>
                <div style="margin-top: 2px;">
                  <el-input v-model="dom.name" placeholder="请输入"></el-input>
                </div>
              </div>
              <div class="pt20" v-if="dom.item_type == 1"></div>
              <div class="remarkds" v-if="dom.item_type == 1">
                <div class='bcedatatitle' style="line-height: 40px;">选项:</div>
                <div style="margin-top: 2px;">
                  <el-input style="min-width: 400px;" v-model="dom.content" placeholder="多项目换行表示" type="textarea" maxlength="200" show-word-limit clearable required></el-input>
                </div>
              </div>
              <div class="pt20" v-if="dom.item_type == 3"></div>
              <div class="remarkds" v-if="dom.item_type == 3">
                <div class='bcedatatitle' style="line-height: 40px;">图片类型:</div>
                <div style="margin-top: 2px;">
                  <el-select v-model="dom.image_type" placeholder="请选择">
                    <el-option
                      v-for="item in domtype3s"
                      :key="item.value"
                      :label="item.name"
                      :value="item.value">
                    </el-option>
                  </el-select>
                </div>
              </div>
              <div class="pt20"></div>
            </div>
            <el-form-item style="text-align: center;">
              <el-button type="primary" @click="submitregisterdata">保存</el-button>
              <el-button @click="eddbankshow = false">取消</el-button>
            </el-form-item>
          </el-form>
        </div>
      </el-dialog>
      <el-dialog
        title="添加"
        :visible.sync="addbankshow"
        :fullscreen="false"
        :modal="false"
        width="80%"
        hight="80%"
        center>
        <div class='conentbox'>
          <el-form ref="form" :model="form" label-width="120px">
            <div class='contflex'>
            <el-form-item label="模板名称" prop="enclosure_name">
              <el-input v-model.trim="form.enclosure_name" placeholder="请输入中文名称" maxlength="50" show-word-limit clearable required></el-input>
            </el-form-item>
            <el-form-item label="案件类型" prop="type">
              <el-select style="width:200px;"  v-model="form.type" placeholder="请选择">
                    <el-option
                      label="行李"
                      value="0">
                    </el-option><el-option
                      label="非行李"
                      value="1">
                    </el-option>
            </el-select>
            </el-form-item>
            <el-form-item label="说明" prop="remark">
              <el-input v-model.trim="form.remark" placeholder="请输入说明内容" type="textarea" maxlength="200" show-word-limit clearable required></el-input>
            </el-form-item>
            <el-form-item label="存储位置" prop="f_type">
              <el-select style="width:200px;" @change="setstatus3" v-model="form.f_type" placeholder="请选择">
                      <el-option
                        v-for="(ot,i) in options"
                        :key="'a'+i"
                        :label="ot.name"
                        :value="ot.value">
                      </el-option>
              </el-select>
              <el-select style="width:200px;"  v-model="form.c_type" placeholder="请选择">
                      <el-option
                        v-for="(ot,i) in options4"
                        :key="'a'+i"
                        :label="ot.name"
                        :value="ot.value">
                      </el-option>
              </el-select>
            </el-form-item>
            <el-form-item prop="c_type"> </el-form-item>
            <el-form-item label="问题类型" prop="enclosure_content">
            <div class="remarkds">
              <div> 
                <el-button class="btnblack" type="info" @click="adddom(1)">下拉框</el-button>
                <el-button class="btnblack" type="info" @click="adddom(2)">输入框</el-button>
                <el-button class="btnblack" type="info" @click="adddom(3)">上传图片</el-button>
                <el-button class="btnblack" type="info" @click="adddom(4)">损失清单</el-button>
                <el-button class="btnblack" type="info" @click="adddom(5)">自行投诉记录</el-button>
              </div>
            </div>
          </el-form-item>
          </div>
          <div style='height:350px;'></div>
            <div class="pt20"></div>
            <div v-for="(dom,index) in form.enclosure_content" :key="index" >
              <div>
                  类型：{{dom.title}} 
                  <el-checkbox class="domcheck malr" v-model="dom.is_must">必填项</el-checkbox>
                  <el-button class="btnblack" type="info" @click="deldom(index)">删除</el-button>
              </div>
              <div class="pt20"></div>
              <div class="remarkds">
                <div class='bcedatatitle' style="line-height: 40px;">问题名称:</div>
                <div style="margin-top: 2px;">
                  <el-input v-model="dom.name" placeholder="请输入"></el-input>
                </div>
              </div>
              <div class="pt20" v-if="dom.item_type == 1"></div>
              <div class="remarkds" v-if="dom.item_type == 1">
                <div class='bcedatatitle' style="line-height: 40px;">选项:</div>
                <div style="margin-top: 2px;">
                  <el-input style="min-width: 400px;" v-model="dom.content" placeholder="多项目换行表示" type="textarea" maxlength="200" show-word-limit clearable required></el-input>
                </div>
              </div>
              <div class="pt20" v-if="dom.item_type == 3"></div>
              <div class="remarkds" v-if="dom.item_type == 3">
                <div class='bcedatatitle' style="line-height: 40px;">图片类型:</div>
                <div style="margin-top: 2px;">
                  <el-select v-model="dom.image_type" placeholder="请选择">
                    <el-option
                      v-for="item in domtype3s"
                      :key="item.value"
                      :label="item.name"
                      :value="item.value">
                    </el-option>
                  </el-select>
                </div>
              </div>
              <div class="pt20"></div>
            </div>
            <el-form-item style="text-align: center;">
              <el-button type="primary" @click="submitForm">提交</el-button>
              <el-button @click="resetForm">重置</el-button>
            </el-form-item>
          </el-form>
        </div>
      </el-dialog>
    </div>
</template>

<script>
// @ is an alias to /src   
import axios from 'axios'
import config from '../../lib/config'

export default {
  name: 'Home',
  data(){
    return {
      form: {
        type: '',
        enclosure_name: '',
        f_type: '',
        c_type: '',
        remark: '',
        enclosure_content: [],
      },
      registerdata:{
        type: '',
        enclosure_name: '',
        f_type: '',
        c_type: '',
        remark: '',
        enclosure_content: [],
      },
      loading:true,
      total:0,
      per_page: 0,
      current_page:1,
      addbankshow:false,
      eddbankshow:false,
      tableData: [],
      options4:[],
      payChannel:[],//支付渠道
      formInline: {
         page:1,//页码 0不分页
         search_data:'',//检索内容
         f_type:0,//父级分类
         c_type:0,//子级分类
      },
      domtype3s:[],
      status:'',//分类1
      status2:'',//分类2
      status3:'',
      status4:'',
      timevalue:'',//交易日期
      // 选中数组
      ghs: [],
      nums:[],
      adddomdata:[],//添加问题类型数据
     //选中的记录数量
     initdata:{
         page:1,//页码 0不分页
         search_data:'',//检索内容
         f_type:0,//父级分类
         c_type:0,//子级分类
     },
     selectedNum:0,
     options:[],
     options2:[],
     pickerOptions: {
          shortcuts: [{
            text: '最近一周',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit('pick', [start, end]);
            }
          }, {  
            text: '最近一个月',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit('pick', [start, end]);
            }
          }, {
            text: '最近三个月',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit('pick', [start, end]);
            }
          }]
        },
    }
  },
  filters: {
    formatDate(timestamp) {
      const date = new Date(timestamp*1000);
      const year = date.getFullYear();
      const month = ('0' + (date.getMonth() + 1)).slice(-2);
      const day = ('0' + date.getDate()).slice(-2);
      const hour = ('0' + date.getHours()).slice(-2);
      const minute = ('0' + date.getMinutes()).slice(-2);
      const second = ('0' + date.getSeconds()).slice(-2);
      return `${year}-${month}-${day} ${hour}:${minute}:${second}`;
    }
  },
  methods: {
      bianji(id){
        let _this = this;
        let data = {
          id:id
        }
        axios.get(config.enclosure_detail,{params:data})
        .then(function (response) {
            if(response.data.code == 200){
              _this.registerdata = response.data.data;
              for(let i in _this.registerdata.enclosure_content){
                _this.registerdata.enclosure_content[i].is_must = _this.registerdata.enclosure_content[i].is_must ? true : false;
              }
              _this.eddbankshow = true;
            }else{
              _this.$message.error(response.data.message);
            }
          })
        .catch(function (error) {
          console.log(error);
        });
      },
      dd(id){
        let _this = this;
        this.$confirm('此操作将删除列, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          axios.delete(config.enclosure,{data:{id:id}})
          .then(response => {
            if(response.data.code == 200){
              _this.$message({
                message: response.data.message,
                type: 'success'
              });
              _this.onSubmit('CHA');
            }else{
                _this.$message.error(response.data.message);
              }
          })
          .catch(error => {
            _this.$message.error(error);
          })
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          });          
        });
       
      },
      submitForm() {// 手动编写非空验证代码
        let _this = this;
        console.log(this.form);
        if (this.form.type === '' || !this.form.enclosure_name || !this.form.f_type === '' || !this.form.c_type === '' || !this.form.remark || !this.form.enclosure_content) {
          _this.$message.error('表单数据不能为空');
          return false;
        }
        for(let i in this.form.enclosure_content){
          this.form.enclosure_content[i].is_must = this.form.enclosure_content[i].is_must ? 1 : 0;
          this.form.enclosure_content[i].content = this.form.enclosure_content[i].content === '' ? '1' : this.form.enclosure_content[i].content;
          this.form.enclosure_content[i].image_type = this.form.enclosure_content[i].image_type === '' ? 0 : this.form.enclosure_content[i].image_type;
        }
        this.$refs.form.validate(valid => {
          if (valid) {
            axios.put(config.enclosure,_this.form)
            .then(function (response) {
                if(response.data.code == 200){
                  _this.$message({
                    message: response.data.message,
                    type: 'success'
                  });
                  _this.onSubmit('CHA');
                  _this.addbankshow = false;
                }else{
                  _this.$message.error(response.data.message);
                }
              })
            .catch(function (error) {
              console.log(error);
            });
          } else {
            _this.$message.error('表单验证失败');
            return false;
          }
        });
      },
      submitregisterdata(){
        let _this = this;
        console.log(this.registerdata)
        if (this.registerdata.type === '' || !this.registerdata.enclosure_name || !this.registerdata.f_type === '' || !this.registerdata.c_type === '' || !this.registerdata.remark || !this.registerdata.enclosure_content) {
          _this.$message.error('表单数据不能为空');
          return false;
        }
        for(let i in this.registerdata.enclosure_content){
          this.registerdata.enclosure_content[i].is_must = this.registerdata.enclosure_content[i].is_must ? 1 : 0;
          this.registerdata.enclosure_content[i].content = this.registerdata.enclosure_content[i].content === '' ? '1' : this.registerdata.enclosure_content[i].content;
          this.registerdata.enclosure_content[i].image_type = this.registerdata.enclosure_content[i].image_type === '' ? 0 : this.registerdata.enclosure_content[i].image_type;
        }

        this.$refs.registerdata.validate(valid => {
          if (valid) {
            axios.post(config.enclosure,_this.registerdata)
            .then(function (response) {
                if(response.data.code == 200){
                  _this.$message({
                    message: response.data.message,
                    type: 'success'
                  });
                  _this.onSubmit('CHA');
                  _this.eddbankshow = false;
                }else{
                  _this.$message.error(response.data.message);
                }
              })
            .catch(function (error) {
              console.log(error);
            });
          } else {
            _this.$message.error('表单验证失败');
            return false;
          }
        });

      },
      setstatus2(){
        let _this = this;
        axios.get(config.selectquestion_type,{params:{pid:this.status}})
        .then(function (response) {
            if(response.data.code == 200){
              _this.options2 = response.data.data;
              _this.status2 = '';
              _this.setpage(response.data.data)
            }else{
              _this.$message.error(response.data.message);
            }
              _this.loading = false
          })
        .catch(function (error) {
          _this.$message.error(error);
        });
      },
      setstatus3(){
        let _this = this;
        axios.get(config.selectquestion_type,{params:{pid:_this.form.f_type}})
        .then(function (response) {
            if(response.data.code == 200){
              _this.options4 = response.data.data;
              _this.form.c_type = '';
              _this.setpage(response.data.data)
            }else{
              _this.$message.error(response.data.message);
            }
              _this.loading = false
          })
        .catch(function (error) {
          _this.$message.error(error);
        });
      },
      setstatus33(){
        let _this = this;
        axios.get(config.selectquestion_type,{params:{pid:_this.registerdata.f_type}})
        .then(function (response) {
            if(response.data.code == 200){
              _this.options4 = response.data.data;
              _this.form.c_type = '';
              _this.setpage(response.data.data)
            }else{
              _this.$message.error(response.data.message);
            }
              _this.loading = false
          })
        .catch(function (error) {
          _this.$message.error(error);
        });
      },
      resetForm() {
        this.$refs.form.resetFields();
      },
      add(){
        this.addbankshow = true;
      },
      exportlist(){

      },
      onSubmit(CHA){
        let _this = this;
        _this.loading = true;
        if(CHA == 'CHA'){
          this.formInline.page = 1;
        }
        _this.formInline.f_type = _this.status ? _this.status : 0;
        _this.formInline.c_type = _this.status2 ? _this.status2 : 0;
        axios.get(config.enclosure,{params:_this.formInline})
        .then(function (response) {
            if(response.data.code == 200){
              _this.tableData = response.data.data.data
              _this.setpage(response.data.data)
            }else{
              _this.$message.error(response.data.message);
            }
              _this.loading = false
          })
        .catch(function (error) {
          _this.$message.error(error);
        });
      },
      deldom2(i){
      let _this = this;
      _this.registerdata.enclosure_content.splice(i,1);
    },
    deldom(i){
      let _this = this;
      _this.form.enclosure_content.splice(i,1);
    },
    adddom2(type){//type 1下拉框 2输入框 3上传图片 4损失清单 5自行投诉记录 
      let _this = this;
      let name = '';
      switch(type){
        case 1:
          name = '下拉框';
          break;
        case 2:
          name = '输入框';
          break;
        case 3:
          name = '上传图片';
          break;
        case 4:
          name = '损失清单';
          break;
        case 5:
          name = '自行投诉记录';
          break;
      }
      _this.registerdata.enclosure_content.push({
        item_type:type,
        title:name,
        is_must:true,
        name:'',
        content:'',
        image_type:'',
      });
    },
    adddom(type){//type 1下拉框 2输入框 3上传图片 4损失清单 5自行投诉记录 
      let _this = this;
      let name = '';
      switch(type){
        case 1:
          name = '下拉框';
          break;
        case 2:
          name = '输入框';
          break;
        case 3:
          name = '上传图片';
          break;
        case 4:
          name = '损失清单';
          break;
        case 5:
          name = '自行投诉记录';
          break;
      }
      _this.form.enclosure_content.push({
        item_type:type,
        title:name,
        is_must:true,
        name:'',
        content:'',
        image_type:'',
      });
    },
      handleSelect(key) {
        this.formInline.order_type = key;
        this.onSubmit('CHA');
      },
      setpage(page){
        this.total = page.total;
        this.per_page = page.per_page;
        this.current_page = page.current_page;
      },typeIndex(index) {
        let _this = this; //处理分页数据的 index
        return (_this.formInline.page - 1) * 20 + index + 1;
      },
      handleCurrentChange(val) {
        this.formInline.page = val;
        this.onSubmit();
        document.getElementsByClassName('el-main')[0].scrollTop = 0;
      },
    // 多选框选中数据
    handleSelectionChange(selection) {
        //获取所有选中项的gh(工号)属性的值
        this.ghs = selection.map(item => item.id)
        this.nums = selection.map(item => item.num)
        //获取所有选中项数组的长度
        this.selectedNum = selection.length
      },
    init(){
        let _this = this;
        axios.get(config.enclosure,{params:this.initdata})
        .then(function (response) {
            if(response.data.code == 200){
              _this.tableData = response.data.data.data
              _this.setpage(response.data.data)
            }else{
              _this.$message.error(response.data.message);
            }
              _this.loading = false
          })
        .catch(function (error) {
          _this.$message.error(error);
        });
        axios.get(config.selectquestion_type,{params:{pid:0}})
        .then(function (response) {
            if(response.data.code == 200){
              _this.options = response.data.data
              _this.setpage(response.data.data)
            }else{
              _this.$message.error(response.data.message);
            }
              _this.loading = false
          })
        .catch(function (error) {
          _this.$message.error(error);
        });
        axios.get(config.selectimage_type)
        .then(function (response) {
            if(response.data.code == 200){
              _this.domtype3s = response.data.data
              _this.setpage(response.data.data)
            }else{
              _this.$message.error(response.data.message);
            }
              _this.loading = false
          })
        .catch(function (error) {
          _this.$message.error(error);
        });
    }
  },
  created(){
      this.init();
  }
}
</script>
<style scoped>
.left{
    width: 70px;
    text-align: left;
}
.remarkds{
    display: flex;
  }
  
  .bcedatatitle{
    min-width: 80px;
  }
  .btnblack{
    background-color: #000;
    color: #fff;
  }
  .malr{
    margin: 0 12px 0 7px;
  }
  .conentbox{
  max-height: 75vh;
  overflow:auto;
}
.contflex{
  position: absolute;
  box-shadow: 5px 5px 5px #888888;
  width: 90%;
  background-color: white;
  z-index: 999;
  top: 75px;
}
</style>